import { RegistrationMode, UserType } from '@norstella/nxp-sso-core/interfaces/sso-api'
import { FormType } from '@norstella/nxp-sso-web/utils/Forms'

const formTypeMapping = {
  [UserType.user]: FormType.signup,
  [UserType.federated]: FormType.federatedSignup,
  [UserType.trial]: FormType.trialSignup,
}

type SignupFormType = FormType.signup | FormType.federatedSignup | FormType.trialSignup | FormType.ensureUserRegistration

export const useSignupFormType = (
  userType: UserType,
  registrationMode: RegistrationMode,
  ): SignupFormType => {
  if (registrationMode === RegistrationMode.ENTITLEMENTS) {
    return FormType.ensureUserRegistration as SignupFormType
  }

  if (registrationMode === RegistrationMode.SIMPLE) {
    return FormType.simpleSignup as SignupFormType
  }

  return formTypeMapping[userType] as SignupFormType || FormType.signup
}
