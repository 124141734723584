import { IAccessToken, useDecodedTokens } from './useDecodedTokens'
import config from '../../../auth0-management-api/src/services/universalTemplate/brandingConfig'
import { FormType, Forms, forms } from '@norstella/nxp-sso-web/utils/Forms'
import { useSearchParams } from 'react-router-dom'

interface IAppBrandingConfig {
  name: string,
  [key: string]: string | object | undefined,
}

export const useBranding = (): [Forms] => {
  const [searchParams] = useSearchParams()
  const [tokens] = useDecodedTokens()
  const accessToken = tokens.accessToken as IAccessToken
  const appGroupId = accessToken?.app_group_id || searchParams.get('applicationGroupId')
  let branding = config.applicationGroups.find((item: IAppBrandingConfig) => item.name === appGroupId)
  let isGeneralBrandingUsed = false

  if (!branding) {
    isGeneralBrandingUsed = true
    branding = config.applicationGroups.find((item: IAppBrandingConfig) => item.name === config.defaultGroupName)
  }

  for (const [formName] of Object.entries(forms)) {
    forms[formName as FormType].header.bgColor = branding?.logoBackground
    forms[formName as FormType].header.image = branding?.logoUrl
    forms[formName as FormType].header.isGeneralBrandingUsed = isGeneralBrandingUsed
    if (formName.indexOf('verify') > -1) {
      forms[formName as FormType].footer = branding?.verifyFooter
    }
  }

  forms[FormType.error].footer = branding?.errorFooter
  forms[FormType.federatedSignup].footer = branding?.fedSignupErrorFooter
  forms[FormType.nonBusinessDomain].contacts = branding?.contacts
  forms[FormType.emailCheckFailed].contacts = branding?.contacts

  return [forms]
}
