import { Typography } from '@mui/material'
import ApplicationCard from '@norstella/nxp-sso-web/components/ApplicationCard'
import { useBranding } from '../hooks/useBranding'

export const NonBusinessDomain = () => {
  const [forms] = useBranding()
  const headerMeta = forms.nonBusinessDomain
  const contactsData = headerMeta.contacts
  let emailLink

  if (contactsData?.email) {
    const emailHref = `mailto:${contactsData.email}`
    emailLink = <a href={emailHref}>{contactsData.email}</a>
  }

  return (
    <>
      {
        <ApplicationCard header={headerMeta.header} description={headerMeta.description}>
          <Typography component="p">
            As a supplier of premium news and data, we ask you to sign up using a valid business email address.
          </Typography>

          <Typography component="p">
            If you do not have one, please contact {emailLink}
          </Typography>
        </ApplicationCard>
      }
    </>
  )
}
