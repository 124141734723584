import { useEffect } from 'react'
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'
import { useNewEntitlementProviderIds } from '@norstella/nxp-sso-web/hooks/useNewEntitlementProviderIds';
import { useApplicationGroupId } from '@norstella/nxp-sso-web/hooks/useApplicationGroupId';
import { useFeatureFlags } from '@norstella/nxp-sso-web/hooks/useFeatureFlags';

export type RedirectData = {
  redirect_uri?: string,
  scope?: string,
  client_id?: string,
  audience?: string,
  entitlementProviderIds?: string[],
  registration_mode?:string
}

export interface ISignupRedirectState {
  state: string|null,
  data: RedirectData|null,
}

const SIGNUP_STATE_KEY = 'nxp-sso:signup_state'
const stateParamsKeys: (keyof RedirectData | 'state')[] = [
  'state',
  'redirect_uri',
  'scope',
  'client_id',
  'audience',
  'entitlementProviderIds',
  'registration_mode',
]

const getSignupRedirectData = (params: URLSearchParams): ISignupRedirectState => {
  return stateParamsKeys.reduce((acc, key) => {
    const value = params.get(key) as string & string[]
    acc.data = acc.data || {}
    if (key === 'state') {
      acc.state = value
    } else {
      acc.data[key] = value
    }
    return acc
  }, {} as ISignupRedirectState)
}

const isSignupRedirectDataValid = ({ state, data }: ISignupRedirectState) => state &&
  data?.redirect_uri &&
  data?.audience &&
  data?.scope &&
  data?.client_id

export const useSignupRedirectState = (): [ISignupRedirectState|undefined, () => void] => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [signupState, setSignupState, { removeItem }] = useLocalStorageState<ISignupRedirectState>(SIGNUP_STATE_KEY, {
    storageSync: true,
  })
  const { isDebugLevelEnabled } = useFeatureFlags()

  const redirectData = getSignupRedirectData(searchParams)
  const entitlementProviderIds = useNewEntitlementProviderIds()
  const applicationGroupId = useApplicationGroupId()

  useEffect(() => {
    const isValid = isSignupRedirectDataValid(redirectData);

    if (isDebugLevelEnabled) {
      console.log('useSignupRedirectState', JSON.stringify({
        isValid,
        redirectData,
      }, null, 2));
    }

    if (isValid) {
      setSignupState(redirectData)

      const queryParams: {[key: string]: string} = {}
      if (entitlementProviderIds.length > 0) {
        queryParams.entitlementProviderIds = entitlementProviderIds.join(',')
      }
      if (applicationGroupId) {
        queryParams.applicationGroupId = applicationGroupId
      }

      if (redirectData.data?.registration_mode) {
        queryParams.registration_mode = redirectData.data.registration_mode
      }

      const queryParamsSerialized = createSearchParams(queryParams).toString().trim()

      navigate(
        `/signup${queryParamsSerialized !== '' ? `?${queryParamsSerialized}` : ''}`,
        {
          replace: true,
        },
      )
    }
  }, [navigate, redirectData, setSignupState, entitlementProviderIds])

  return [signupState, removeItem]
}
